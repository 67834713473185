<template>
  <div class="mcp-table">
    <div class="spinner" v-if="loading">
      <div class="line1"></div>
    </div>
    <div v-else-if="counter === 0">
      <div class="mcp_empty">
        <svg
          data-v-12ab04be=""
          version="1.1"
          viewBox="0 0 296 207"
          class="svg-icon svg-fill"
          style="width: 150px"
        >
          <path
            pid="0"
            d="M148 206.912c81.738 0 148-18.466 148-41.246 0-22.779-66.262-41.246-148-41.246S0 142.887 0 165.666c0 22.78 66.262 41.246 148 41.246z"
            fill="url(#paint0_linear_930_10567)"
          ></path>
          <path
            pid="1"
            d="M93.097 15.162H205.15c2.29 0 4.15 1.86 4.15 4.15v142.716c0 2.29-1.86 4.15-4.15 4.15H93.097c-2.29 0-4.15-1.86-4.15-4.15V19.311a4.157 4.157 0 014.15-4.15z"
            fill="#D2D2D2"
          ></path>
          <path
            pid="2"
            d="M99.129 15.162h111.785a4.286 4.286 0 014.284 4.283v142.449a4.287 4.287 0 01-4.284 4.284H99.129a4.286 4.286 0 01-4.283-4.284V19.444a4.28 4.28 0 014.283-4.282z"
            fill="#E1E1E1"
          ></path>
          <path
            pid="3"
            d="M109.155 25.544h92.785a2.15 2.15 0 012.149 2.148v126.962a2.15 2.15 0 01-2.149 2.149h-92.785a2.15 2.15 0 01-2.149-2.149V27.692a2.15 2.15 0 012.149-2.148z"
            fill="#FAFAFC"
          ></path>
          <path
            pid="4"
            d="M186.749 71.555h-36.711a2.8 2.8 0 01-2.794-2.794v-7.833a2.8 2.8 0 012.794-2.794h36.711a2.8 2.8 0 012.794 2.794v7.833c.007 1.534-1.253 2.794-2.794 2.794zM137.766 70.628h-12.968a2.8 2.8 0 01-2.794-2.793v-7.833a2.8 2.8 0 012.794-2.794h12.968a2.8 2.8 0 012.793 2.794v7.833c.008 1.533-1.252 2.793-2.793 2.793zM186.748 96.009h-60.187a2.8 2.8 0 01-2.793-2.794v-7.833a2.8 2.8 0 012.793-2.793h60.187a2.8 2.8 0 012.794 2.793v7.833a2.791 2.791 0 01-2.794 2.794zM186.748 121.389h-60.187a2.8 2.8 0 01-2.793-2.794v-7.832a2.8 2.8 0 012.793-2.794h60.187a2.8 2.8 0 012.794 2.794v7.832a2.79 2.79 0 01-2.794 2.794z"
            fill="#ECECEC"
          ></path>
          <path
            pid="5"
            d="M122.004 31.494V14.517c0-2.875 2.356-5.232 5.232-5.232h18.437C146.658 4.002 151.29 0 156.862 0c5.573 0 10.204 4.002 11.19 9.285h16.266c2.875 0 5.231 2.357 5.231 5.232v16.977h-67.545z"
            fill="#D2D2D0"
          ></path>
          <path
            pid="6"
            d="M117.713 25.544l4.291 5.95v-5.95h-4.291z"
            fill="#BDBDBE"
          ></path>
          <path
            pid="7"
            d="M156.663 17.844a6.936 6.936 0 100-13.873 6.936 6.936 0 000 13.873z"
            fill="#C4C4C4"
          ></path>
          <defs>
            <linearGradient
              id="paint0_linear_930_10567"
              x1="148.003"
              y1="206.912"
              x2="148.003"
              y2="124.42"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#fff"></stop>
              <stop offset="1" stop-color="#E7E7E7"></stop>
            </linearGradient>
          </defs>
        </svg>
        <h3>Aucun résultat</h3>
      </div>
    </div>
    <div v-else>
      <div class="mcp-container">
        <table class="table is-hoverable is-fullwidth">
          <thead>
            <tr>
              <th
                v-for="column in columns"
                :class="
                  column.state != undefined && [0,2].includes(column.state)
                    ? 'hidden_'
                    : ''
                "
                :key="column.name"
              >
                {{ column.label }}
              </th>
            </tr>
          </thead>
          <slot name="body"></slot>
        </table>
      </div>
      <div class="mcp-table-footer">
        <nav
          class="pagination is-small"
          role="navigation"
          aria-label="pagination"
        >
          <ul class="pagination-list">
            <li v-for="(link, key) in meta.links" :key="key">
              <a @click.prevent="navigation(link)" :class="getClass(link)">{{
                link.label
              }}</a>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    columns: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
    counter: {
      type: Number,
      default: 0,
    },
    meta: {
      type: Object,
      default: () => {},
    },
    navigationFunction: {
      type: Function,
    },
  },
  data: () => ({
    currentPage: 1,
    links: [],
  }),

  methods: {
    getClass(item) {
      if (item.url === null) return "pagination-link is-disabled no-cursor";
      if (item.url != null) {
        if (item.active === true) {
          return "pagination-link is-current no-cursor";
        }
        return "pagination-link";
      }
    },
    navigation(nav) {
      const url = nav.url.split("=");
      this.navigationFunction(url[1]);
    },
  },
};
</script>

<style lang="scss" scoped>
.hidden_ {
  display: none;
}
td,
th {
  padding-bottom: 10px;
  padding-top: 10px;
}

.spinner {
  width: 100%;
  height: 3px;
  overflow: hidden;
  margin: auto;
}

.spinner > div {
  background-color: #cf0c1a;
  width: 50%;
  height: 100%;
  margin-top: 0;
  margin-left: -25%;
  animation: bounce_loadingProgress 1.5s infinite ease-in-out both;
}

.mcp-table-footer {
  display: flex;
  padding-top: 20px;
  justify-content: flex-end;
}

@keyframes bounce_loadingProgress {
  0% {
    margin-left: -25%;
  }
  50% {
    margin-left: 100%;
  }
}

@-webkit-keyframes bounce_loadingProgress {
  0% {
    margin-left: -25%;
  }
  50% {
    margin-left: 100%;
  }
}

.mcp_empty {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 30px;
}

::-webkit-scrollbar {
  width: 1px;
  height: 5px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: rgb(203, 201, 201);
}

::-webkit-scrollbar-thumb:hover {
  background: rgb(203, 201, 201);
}
</style>