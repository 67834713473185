<template>
  <div class="mcp-search">
    <div class="bt-flex">
      <slot name="buttons"></slot>
    </div>
    <input
      v-if="showSearch"
      v-model="search"
      @keyup.enter="doSearch"
      style="width: 20% !important"
      class="input is-small"
      type="text"
      placeholder="Recherche"
    />
  </div>
</template>

<script>
export default {
  props: {
    makeSearch: {
      type: Function,
    },
    showSearch: {
      type: Boolean,
      default: () => true,
    },
  },
  data: () => ({
    search: "",
  }),
  methods: {
    doSearch() {
      this.makeSearch(this.search);
      this.search = "";
    },
  },
};
</script>

<style lang="scss" scoped>
.bt-flex {
  display: flex;
  gap: 10px;
}
</style>